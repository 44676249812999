<template>
    <div>
        <v-toolbar
                dense
                tabs
                color="primary darken-2"
                dark
        >
            <v-toolbar-title class="pl-2">Signup</v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 offset-md2>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <v-card class="blue lighten-4" style="" >
                                <v-card-text style="" class=" pa-2">
                                    <p class="dark--text adjust-text-size pa-0 ma-0 text-xs-center"><span> Bravo… your account has been created. Fill the information below with a snapshot of any ID Card inorder to activate it.</span></p>
                                </v-card-text>
                            </v-card>

                            <v-card color="white pa-3 " style="background-color: rgb(236, 243, 250)!important" >
                                <b>Step 2/3: Personal & Professional Info</b>
                                <v-layout row>
                                    <v-flex xs12 md10 offset-md1>            
                                        <v-layout row>
                                            <v-flex xs11 class="mt-1 mb-2">
                                                <div style="border-bottom: 1px solid grey; width: 100%; cursor: pointer"  @click="$refs.file.click()">
                                                    <input @change="fileInput()" type="file" ref="file" name="file" id="file" class="inputfile" accept=".jpg,.png,.jpeg,.pdf" style=""/>
                                                    <label for="file"><strong><i class="fas fa-upload "> </i> Upload</strong></label>
                                                    <span class="text-xs-center" style="font-size: 12px; width: 100%">  {{inputFileName}}</span>
                                                </div>
                                            </v-flex>
                                            <v-flex xs1 class="text-xs-left ma-0 pa-0" >
                                                <v-btn  class="text-xs-left mt-3 pa-0" @click.stop="$store.commit('activateDialog', true); upload_file_help = true; getCampaignFieldHelp()" flat fab small outline color="black" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="mt-2 ml-1 fas fa-person-half-dress fa-2x"> </i>                            
                                            </v-flex>
                                            <v-flex xs9 sm10>
                                                <v-select
                                                class="pt-0" ref="gender"
                                                v-model="gender"
                                                :items="items"
                                                :rules="[v => !!v || 'Gender is required']"
                                                label="Select Gender"
                                                required
                                                ></v-select>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); gender_help = true; getCampaignFieldHelp()" flat fab small outline color="black" class="mt-2" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-user-tie fa-2x "> </i>                            
                                            </v-flex>
                                            <v-flex xs9 sm10>
                                                <v-text-field 
                                                    class="pt-0 mt-0" ref="profession"
                                                    id="profession" v-model="profession"
                                                    label="Enter Your Profession"
                                                    :rules="[v => !!v || 'Your profession is required']"
                                                    required
                                                    single-line 
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); profession_help = true; getCampaignFieldHelp()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>

                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-envelope fa-2x "> </i>                            
                                            </v-flex>
                                            <v-flex xs9 sm10>
                                                <v-text-field 
                                                    class="pt-0 mt-0" ref="email"
                                                    id="email" v-model="email"
                                                    label="Enter Your Email"
                                                    :rules="emailRules"
                                                    required
                                                    single-line 
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); email_help = true; getCampaignFieldHelp()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>

                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-map-location-dot fa-2x "> </i>                            
                                            </v-flex>
                                            <v-flex xs9 sm10>
                                                <v-text-field 
                                                    class="pt-0 mt-0" v-model="residence"
                                                    id="residence" ref="residence"
                                                    label="Residential Address"
                                                    :rules="[v => !!v || 'Your Residential Address is required']"
                                                    required
                                                    single-line 
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); residence_help = true; getCampaignFieldHelp()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout> 
                                    </v-flex>
                                </v-layout>
                                <div class=" text-xs-right">
                                    <v-btn small class="white--text" round color="teal darken-2" @click="continueCreateAccount()" >
                                        <span> Next</span>
                                    </v-btn>
                                </div>
                            </v-card>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                inputFileName: "Image/PDF of Your ID Card ",
                upload_file_help: false,
                items: [
                    'Male',
                    'Female',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid'
                ],
                file: "",
                gender: "",
                email: "",
                profession: "",
                residence: "",
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | SignUp"
            // if(this.$store.state.isAuthenticated){
            //     this.$store.commit('setSnackBarMessage', "You are currently signed into an account.")
            //     this.$store.commit('setSnackBarColor', "red darken-4")
            //     this.$store.commit('activateSnackBar', true)    
            //     var navigation = this.$router
            //     // const toPath = '/'
            //     console.log(this.prevRoute)
            //     navigation.push("/")
            // }        
        },

        methods: {
            getCampaignFieldHelp(){
                if (this.upload_file_help){
                    this.upload_file_help = false
                    this.$store.commit('setDialogTitle', "ID Card")
                    this.$store.commit('setDialogMessage', "For your identification, and legal purposes, please send us a copy of your ID card.")
                    this.$store.commit('activateDialog', true)
                }else if (this.gender_help){
                    this.gender_help = false
                    this.$store.commit('setDialogTitle', "Your Gender")
                    this.$store.commit('setDialogMessage', "Select your respective gender.")
                    this.$store.commit('activateDialog', true)
                }else if (this.profession_help){
                    this.profession_help = false
                    this.$store.commit('setDialogTitle', "Your Job/Profession")
                    this.$store.commit('setDialogMessage', "Please input your current source of income as in a job or occupation.")
                    this.$store.commit('activateDialog', true)
                }else if (this.email_help){
                    this.email_help = false
                    this.$store.commit('setDialogTitle', "Your Email Address")
                    this.$store.commit('setDialogMessage', "Please enter your email address.")
                    this.$store.commit('activateDialog', true)
                }else if (this.residence_help){
                    this.residence_help = false
                    this.$store.commit('setDialogTitle', "Your Residence")
                    this.$store.commit('setDialogMessage', "Please input your current residential location.")
                    this.$store.commit('activateDialog', true)
                }
            },

            fileInput(){
                var File= document.getElementById('file')
                if(File.files.item(0)){
                    var FileName = File.files.item(0).name
                    var FileSize = File.files.item(0).size
                    var FileType = File.files.item(0).type
                    console.log(FileType)
                    FileName = FileName.replace(/.*[\/\\]/, '_')  // https://stackoverflow.com/questions/3437786/get-the-size-of-the-screen-current-web-page-and-browser-window
                    var win = window,
                        doc = document,
                        docElem = doc.documentElement,
                        body = doc.getElementsByTagName('body')[0],
                        screenWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                        screenHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
                    
                    if (FileSize >= 5242880){
                        this.$store.commit('setSnackBarMessage', "Your ID File exceeded the size limit of 5Mb. Upload a file less than 5 megabytes")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Image/PDF of Your ID Card "
                    }
                    else if(!FileType.includes("pdf") && !FileType.includes("jpeg") && !FileType.includes("jpg") && !FileType.includes("png") && !FileSize.includes("doc")){
                        this.$store.commit('setSnackBarMessage', "Your ID File must of format PDF, Image (.png, .jpeg, .jpg) or a file of format .doc")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Image/PDF of Your ID Card "
                    }
                    else{
                        if (FileName.length > 20){
                            if (screenWidth <= 760){
                                FileName = FileName.substring(0, 10) + '...' + FileName.substring(FileName.length-9, FileName.length) 
                            }
                        }
                        this.inputFileName = FileName
                        this.file = this.$refs.file.files[0]
                    }
                }
                else{
                    this.inputFileName = "Image/PDF of Your ID Card "
                }
            },

            async continueCreateAccount(){
                var File= document.getElementById('file')
                console.log(File)
                if(!File.files.item(0)){
                    this.$store.commit('setSnackBarMessage', "Please upload a copy of your ID Card")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.file.focus()  // this causes a an auto fucos to the element
                }
                else if(this.gender === ""){
                    this.$store.commit('setSnackBarMessage', "Please select your gender")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.gender.focus()  // this causes a an auto fucos to the element
                }
                else if(this.profession === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your profession")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.profession.focus()  // this causes a an auto fucos to the element
                }
                else if(this.email === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your email address")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.email.focus()  // this causes a an auto fucos to the element
                }
                else if(this.residence === ""){
                    this.$store.commit('setSnackBarMessage', "Please input where you currently reside.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.residence.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    let formData = new FormData()
                    formData.append('file', this.file)
                    formData.append('gender', this.gender)
                    formData.append('email', this.email)
                    formData.append('profession', this.profession)
                    formData.append('residence', this.profession)
                    console.log(this.file)
                    await axios
                        .post('/api/v1/home/create/account/profile', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            console.log(response.data)
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)

                            var navigation = this.$router
                            if (response.data[0].valid){
                                setTimeout(function(){
                                    navigation.push('/savings/campaigns/create/new_campaign')
                                }, 4000)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }

            },

        },

    }
</script>
<style >
    @media (max-width: 499px) {
        .adjust-text-size{
            font-size: 12px
        }
    }
    @media (min-width: 499px) {
        .adjust-text-size{
            font-size: 14px
        }
    }
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }
</style>
